import React from "react";
import "../../App.css";
import Photos from "./Photos";

function Projects() {
  return (
    <>
      <Photos />
    </>
  );
}

export default Projects;
