import React from "react";
import CardItem from "./CardItem";
import "./HomeContent.css";
import roadBuilding from "./../../images/roadbuilding.jpg";
import demo3 from "./../../images/demo3.jpg";
import billdev from "./../../images/billdev.jpg";

function HomeContent() {
  return (
    <div className="cards">
      <h1>About Terra Shapes.</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src={demo3}
              text="Terra Shapes is a Excavation Company servicing Coeur d'Alene and North Idaho. Our commitment to Safety and Efficiency ensures Affordable rates while maintaining our core values of excellence. 
              Our experienced staff has extensive knowledge of soil compaction, grading methods, slope stability, drainage solutions, site preparation (including clearing), demolition work, septic systems installation/repair/maintenance, water lines & sewer lines installation/repair/maintenance.
              Terra Shapes is a licensed, Bonded and Insured Contractor. We are nimble enough to dig and egress window downtown CDA but big enough to work commercial projects and developments. We strive to give complete satisfaction guaranteed on every job!"
              label="Terra Shapes Excavation"
              alt="Terra Shapes Excavation and Demolition"
              path="/contact"
            />
            <CardItem
              src={billdev}
              text="Meet Bill and Devon (owners) With over 30 years of experience, our excavation company has been providing reliable and high-quality services to clients in the area. We specialize in a variety of excavation projects including site preparation, demolition, grading, material hauling, sewers and septic. Our team is comprised of experienced professionals who are committed to delivering quality results with every project we undertake. We use only the best equipment and materials available so that you can be sure your project will be completed efficiently and safely. With our vast knowledge and experience in the industry, we guarantee satisfaction with all the work we do for you."
              label="About Us"
              alt="About Terra Shapes"
              path="/contact"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={roadBuilding}
              text="Fun job down on Lake Coeur d'Alene. Working a road access and building pad on Rockford bay. We could barely fit our et145 down the trail when we arrived. We carved into the hill side and pushed the material out to create a wider road and pad at the bottom. Here is the access as we brought materials in to create a safe drive down to the lake property even when the weather isn't this great.  "
              label="2022 job spotlight"
              alt="Coeur d alene Road building"
              path="/contact"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default HomeContent;

<p>
  Terra Shapes is a Excavation Company service Coeur d'Alene and North Idaho. We
  are professional and Experienced Excavators with many years under our belts.
  We offer Safe and Efficient digging services at Affordable rates for all
  projects. We Specialize in site preparation and Utility Installation. Terra
  Shapes is a licensed, Bonded and Insured Contractor. We strive giving complete
  satisfaction guaranteed on every job!
</p>;
