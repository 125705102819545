import React from 'react';
import './Footer.css';
import { Button } from '../Button.js'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Terra Shapes Coeur d'Alene, ID North Idaho's Excavation Solutions
        </p>
       {/* <p className='footer-subscription-text'>
          Contact us via Email
        </p>
         <div className='input-areas'>
          <form>
            <input 
            className='footer-input'
            name='email'
            type='email'
            placeholder='Your Email'
            />
            <input
              className='footer-input'
              name='massage'
              type='message'
              placeholder='Your Message'
            />
            <Button buttonStyle='btn--outline'>Contact</Button>
          </form>
        </div> */}
      </section>
      
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
         
          <div class='footer-link-items'>
            <Link to='/contact'>Contact</Link>
            <Link to='/services'>Services</Link>
            <Link to='/Projects'>Projects</Link>
            <Link to='/'>Home</Link>
          </div>
        </div>
        </div>
        <div class='social-media-wrap'>
          
            <Link to='/' className='social-logo'>
              <img className='footer-logo' src='./img/Terrashapeslogo1.png' alt='terra shapes cda'>
              </img>
              
              <h3> &nbsp; © 2022 </h3>
            </Link>
          </div>
        </div>
  );
}

export default Footer;