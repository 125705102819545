import React from 'react';
import '../../App.css';


import HeroSection from '../HeroSection';
import HomeContent from './HomeContent';


function Home() {
  return (
    <>
      <HeroSection /> 
      <HomeContent />
    </>
  );
}

export default Home;