import React from 'react';
import './Services.css';
import exImage from './../../images/finishedsitework.jpg';
import landImage from './../../images/landclearing.jpg';
import sewerImage from './../../images/sewer.jpg';
import demoImage from './../../images/demo2.jpg';
import geoImage from './../../images/geothermal.jpg';
import retainImage from './../../images/Retaining.jpg';
import CardItem from './CardItem';

function Services() {
  return (
    <div className='cards'>
      <h1>Full Service Excavation Company.</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={exImage} 
              text='Excavation solutions including site prep, grading, trenching, Digging and backfill for foundations, Swimming pools, and Landscape Proects. Terra Shapes has the size and expertiece to get small had access projects and larger commercial project done safely and efficiently.'
              label='Site Prep'
              path='/contact'
            />
            <CardItem
              src={landImage}
              text='Land clearing involves the removal of trees, roots, and other vegetation from a piece of land. Preparring a plot of land for construction or landscaping purposes, go from woods to road with a house pad a property thats fire safe. We love the natural beauty of North Idaho and we can help keep it'
              label='Land Clearing and developement'
              path='/contact'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={sewerImage}
              text='Full service Sewer and Septic (standard and complex) repair, specializing in the repair, maintenance, and installation of all types of residential and commercial sewer systems. We Take S--- seriously if you have and emergency sewer or septic backup give us a call! From broken pipe to new installation we got it!'
              label='Sewer and Septic'
              path='/contact'
            />
            <CardItem
              src={demoImage}
              text='Demo site clearing and clean up, demolition services including concrete breaking and hauling, Driveway removal residential and commercial building removal. All demo is responsibly recycled. We carefully plan our demo projects ensure saftey efficiency and compliance with local building codes'
              label='Demolition Contractor'
              path='/contact'
            />
            <CardItem
              src={retainImage}
              text='Specializing in Block wall, Poured Concrete walls and Stacked stone walls. Terra Shapes can help you pick the best material based on weight of soil and water pressure behind it. We design walls based on your needs to support landscapes and structures while protecting against erosion'
              label='Retaining'
              path='/contact'
            />
            <CardItem
              src={geoImage}
              text='
              Geothermal heating and cooling systems provide a cost-effective, energy-efficient way to heat and cool your home. By installing a underground loop field that circulates water or other liquid through pipes the system uses the natural temperature of the earth to regulate indoor temperatures in both hot and cold climates. 
              '
              label='Geo Thermal'
              path='/contact'
            />
            
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Services;