import React from "react";
import "./Photos.css";
import CardItem from "./CardItem";
import img1 from "./photos/img1.jpg";
import img2 from "./photos/img2.jpg";
import img3 from "./photos/img3.jpg";
import img4 from "./photos/img4.jpg";
import img5 from "./photos/img5.jpg";
import img6 from "./photos/img6.jpg";
import img7 from "./photos/img7.jpg";
import img8 from "./photos/img8.jpg";
import img9 from "./photos/img9.jpg";
import img10 from "./photos/img10.jpg";
import img11 from "./photos/img11.jpg";
import img12 from "./photos/img12.jpg";
import img13 from "./photos/img13.jpg";
import img14 from "./photos/img14.jpg";
import img15 from "./photos/img15.jpg";
import img16 from "./photos/img16.jpg";
import img17 from "./photos/img17.jpg";
import img18 from "./photos/img18.jpg";
import img19 from "./photos/img19.jpg";
import img20 from "./photos/img20.jpg";
import img21 from "./photos/img21.jpg";
import img22 from "./photos/img22.jpg";

function Photos() {
  return (
    <div className="cards">
      <div className="cards__container_photos">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src={img1}
              text="Fitting in tight sopt in CDA backfilling utilities"
              path="/contact"
            />
            <CardItem
              src={img2}
              text="Mobilizing the et145 on Lake CDA"
              path="/contact"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={img3}
              text="This garage is ready for concrete"
              path="/contact"
            />

            <CardItem
              src={img4}
              text="Guarentee so good, we'll put a skid steer on it"
              path="/contact"
            />
            <CardItem
              src={img5}
              text="Final grading of a mountain side turned flat"
              path="/contact"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={img6}
              text="Expanding a drive way, exposing a landscape feature"
              path="/contact"
            />

            <CardItem
              src={img7}
              text="Utilities install Water, Sewer and Power"
              path="/contact"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={img8}
              text="King of the mountain hauling dirt today"
              path="/contact"
            />
            <CardItem
              src={img9}
              text="Emergency sewer repair, nights and weekends"
              path="/contact"
            />

            <CardItem
              src={img10}
              text="Demolition squad at work"
              path="/contact"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={img11}
              text="Egress window installation"
              path="/contact"
            />
            <CardItem
              src={img12}
              text=" Site is ready for foundation"
              path="/contact"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={img13}
              text="Geo Thermal install Coeur d'Alene"
              path="/contact"
            />
            <CardItem
              src={img14}
              text="Water leak fix on finished basement"
              path="/contact"
            />

            <CardItem
              src={img15}
              text="Making some room for a shop"
              path="/contact"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={img16}
              text="Board form for concrete retaining wall"
              path="/contact"
            />
            <CardItem
              src={img17}
              text="Stripping forms on concrete retaining wall"
              path="/contact"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={img18}
              text="Dig for full height basement and addition"
              path="/contact"
            />
            <CardItem
              src={img19}
              text="Sewer replacment Coeur d'Alene"
              path="/contact"
            />

            <CardItem
              src={img20}
              text="Grappling for forestry and site cleanup"
              path="/contact"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={img21}
              text="Compaction for footing"
              path="/contact"
            />
            <CardItem
              src={img22}
              text="Emergency sewer replacment Coeur d'Alene"
              path="/contact"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Photos;
