import React from "react";
import Navbar from "..//src/components/Navbar";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/Pages/Home";
import ContactForm from "./contact/Contact.js";
import Services from "./components/Pages/Services.js";
import Footer from "./components/Pages/Footer.js";
import Projects from "./components/Pages/Projects.js";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/Contact" element={<ContactForm />}></Route>
          <Route path="/Services" element={<Services />}></Route>
          <Route path="/Projects" element={<Projects />}></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
