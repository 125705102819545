import React from "react";

import "./contact.css";

/*  const ContactForm = () => {
  const [formData, setFormData] = useState({});

  const updateInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail();
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };
  const sendEmail = () => {
    Axios.post(
      "https://us-central1-terra-shapes.cloudfunctions.net/submit-1",
      formData
    )
      .then((res) => {
        db.collection("emails").add({
          name: formData.name,
          email: formData.email,
          message: formData.message,
          time: new Date(),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }; */

function ContactForm() {
  return (
    <div className="form">
      <div className="content-info">
        <h5>
          Thank you for considering us for your excavation project. We are
          confident that our team has the knowledge and experience to provide
          you with the highest quality service. Please feel free to contact us
          anytime if you have any further questions or would like to schedule a
          consultation. We look forward to working with you!
        </h5>
      </div>
      {/* <form onSubmit={handleSubmit}>
        <input
          className="input"
          type="text"
          name="name"
          placeholder="Name"
          onChange={updateInput}
          value={formData.name || ""}
        />
        <input
          className="input"
          type="email"
          name="email"
          placeholder="Email"
          onChange={updateInput}
          value={formData.email || ""}
        />
        <textarea
          className="textarea"
          type="text"
          name="message"
          placeholder="Message"
          onChange={updateInput}
          value={formData.message || ""}
        ></textarea>
        <button className="button" type="submit">
          Submit
        </button>
  </form> */}
      <div className="buttonDiv">
        <button className="button" type="link">
          <a href="tel:2086760310">Call Us</a>
        </button>
      </div>
      <div className="buttonDiv">
        <button
          className="button"
          onClick={(e) => {
            window.location.href = "mailto:terrashapes@gmail.com";
          }}
        >
          Email us{" "}
        </button>
      </div>
    </div>
  );
}

export default ContactForm;
