import React from 'react';
import './Button.css'

const STYLES = ['btn--primary', 'btn--outline', 'btn--test'];

const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <a href="tel:2086760310">
    <button
    className={`callBtn ${checkButtonStyle} ${checkButtonSize}`}
    onClick={onClick}
    type={type}
  >
     {children}
    </button>
    </a>
    
)};
